import { NEXT_PUBLIC_CONFIG_RUNTIME } from "../types";

export interface AppConfigParams {
  owner: string;
  top_level_domain: string;
  dsn: string;
}

export const isClientSide = (): boolean => typeof window !== "undefined";

const constructApiUrl = ({ owner, top_level_domain }: Omit<AppConfigParams, "dsn">): string => {
  if (window.location.hostname.startsWith("dev.") || window.location.hostname === "localhost") {
    return `https://api.dev.${owner}.${top_level_domain}`;
  }
  if (window.location.hostname.startsWith("ppd.")) {
    return `https://api.ppd.${owner}.${top_level_domain}`;
  }
  return `https://api.${owner}.${top_level_domain}`;
};

/**
 * Dynamically evaluates the client-side environment variables following the same construction rules as the terraform here: https://github.com/MaxKelsen/mk-propel-terraform/blob/main/modules/tenancy/cloud-run.tf
 * @param owner
 * @param top_level_domain
 * @param dsn
 * @returns
 */
export const getAppConfig = ({ owner, top_level_domain, dsn }: AppConfigParams): NEXT_PUBLIC_CONFIG_RUNTIME => {
  if (process.env.NEXT_PUBLIC_IS_E2E) {
    return {
      NEXT_PUBLIC_API_URL: "http://localhost:3000",
      NEXT_PUBLIC_SENTRY_DSN: "",
    };
  }

  const onClient = isClientSide();

  return {
    NEXT_PUBLIC_API_URL: onClient ? constructApiUrl({ owner, top_level_domain }) : process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_SENTRY_DSN: onClient ? dsn : process.env.NEXT_PUBLIC_SENTRY_DSN,
  };
};
