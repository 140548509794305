import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import * as Sentry from "@sentry/nextjs";
import { notification } from "antd";
import { StatusCodes } from "http-status-codes";
import { notificationDescription } from "./formatters.helpers";
import { isClientSide } from "./nextPublicConfigRuntime";

type ErrorMeta<T> = {
  fulfilledTimeStamp: number;
  baseQueryMeta: FetchBaseQueryMeta;
  arg: {
    type: string;
    subscribe: boolean;
    subscriptionOptions: { pollingInterval: number };
    endpointName: T;
    originalArgs: Record<string, unknown>;
    queryCacheKey: string;
  };
  requestId: string;
  requestStatus: string;
};

export type MessageMapper<T> = (
  endpointName: T,
  status: StatusCodes,
  apiErrorMessage?: string,
) => { message: string | null; description?: string; unexpected: boolean; isReload?: boolean };

/**
 * Create an error-logging function that can be used to capture any errors
 * that occur during RTK requests.
 * @param messageMapper Function that maps endpoints to their response messages
 * @returns Error-logging function that can be used with configureStore from RTK
 */
export function generateRTKErrorLogger<T>(messageMapper: MessageMapper<T>): Middleware {
  const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      const meta: ErrorMeta<T> = action.meta as ErrorMeta<T>;
      if (meta && meta.baseQueryMeta.response) {
        const endpointName = meta.arg.endpointName;
        const status: StatusCodes = meta.baseQueryMeta.response?.status;
        const apiErrorMessage = action?.payload?.data?.detail;
        const error = messageMapper(endpointName, status, apiErrorMessage);
        if (error && error.message && isClientSide()) {
          const notificationError = error.description
            ? {
                message: error.message,
                description: notificationDescription(error.description, error.isReload),
                duration: 0,
              }
            : { message: error.message };
          notification.error(notificationError);
        }

        if (error.unexpected) {
          const apiError = {
            endpointName,
            method: meta.baseQueryMeta.request.method,
            status,
            originalArgs: JSON.stringify(meta.arg.originalArgs),
            response: JSON.stringify(action.payload),
            url: meta.baseQueryMeta.request.url,
          };

          console.error("An unexpected error occurred:", apiError);

          Sentry.captureException(new Error(`rtkQueryErrorLogger: ${endpointName}`), {
            contexts: {
              apiError,
            },
          });
        }
      }
    }

    return next(action);
  };

  return rtkQueryErrorLogger;
}

export const logError = (message: string, error: unknown): void => {
  if (isClientSide()) {
    notification.error({ message });
  }

  console.error(message, error);
  Sentry.captureException(error, { contexts: { appError: { message } } });
};
