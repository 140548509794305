import React from "react";
import { Typography } from "antd";
import { reloadPage } from "./";

export const notificationDescription = (description: string, isReload?: boolean): JSX.Element => (
  <>
    <Typography.Text>{description}</Typography.Text>
    {isReload && (
      <div>
        Please <a onClick={reloadPage}>refresh the page.</a>{" "}
      </div>
    )}
  </>
);
