import { AppConfig, DecodedIdToken, RawSideBarItem, RouteType, SideBarItem, Token } from "@project/core/types";
import { unixTimestampToDate } from "@project/core/utils/date.helpers";
import { isFuture } from "date-fns";
import jwt_decode from "jwt-decode";
import { Actor, hasRole } from "./authorization";

/**
 * Takes an App's config object, and evaluates all the supplied functions
 * for the sideBarItem's `icon` key. This allows for dynamic rendering of the
 * side bar icons.
 * @param appConfig Application-specific config, defined in appConfig.ts
 * @param iconParamsMap Map from side bar item key, to parameters passed into icon function
 * @returns An array of 'processed' sidebar items
 */
export const processSideBarItems = <R extends RouteType>(
  appConfig: AppConfig<R>,
  iconParamsMap: Record<string, unknown>,
  actor: Actor,
): SideBarItem<R>[] =>
  Object.entries(appConfig.sideBarItems)
    .map(([key, item]: [string, RawSideBarItem<R>]) => {
      return {
        ...item,
        // Pass parameters into the icon function based on the key of the item
        icon: item.icon(iconParamsMap[key]),
      };
    })
    .filter(item => (item.allowedRoles ? hasRole({ ...actor, allowedRoles: item.allowedRoles ?? [] }) : true));

export const checkIdTokenExpiry: AuthorizedCallback = ({ token }) => {
  if (token) {
    const _token: Token = token as unknown as Token;

    const idToken = _token.idToken;
    if (!idToken) {
      return false;
    }
    const decodedIdToken = jwt_decode<DecodedIdToken>(idToken);
    const tokenIsNotExpired = isFuture(unixTimestampToDate(decodedIdToken.exp));

    return tokenIsNotExpired;
  }
  return false;
};

export const reloadPage = (): void => {
  window.location.reload();
};
