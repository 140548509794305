import antdNotificationConfig from "@project/config/notification.config";
import { colors } from "@project/core/styles/theme";
import { ConfigProvider } from "antd";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import Router from "next/router";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { HashLoader } from "react-spinners";
import "@project/core/styles/global.css";
import "styles/global.css";
import { wrapper } from "store";

const App: React.FC<AppProps<{ session: Session }>> = ({ Component, pageProps: { session, ...pageProps } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { store, props } = wrapper.useWrappedStore(pageProps);

  antdNotificationConfig();

  useEffect(() => {
    Router.events.on("routeChangeStart", () => {
      setIsLoading(true);
    });

    Router.events.on("routeChangeComplete", () => {
      setIsLoading(false);
    });

    Router.events.on("routeChangeError", () => {
      setIsLoading(false);
    });

    /**
     * This block enables us to manipulate and assert on
     * the Redux store from within Cypress tests.
     * DO NOT REMOVE
     */
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.store = store;
    }

    return () => {
      Router.events.off("routeChangeStart", () => {
        setIsLoading(false);
      });

      Router.events.off("routeChangeComplete", () => {
        setIsLoading(false);
      });

      Router.events.off("routeChangeError", () => {
        setIsLoading(false);
      });
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primaryBlue,
        },
      }}
    >
      <SessionProvider session={session}>
        <Provider store={store}>
          {isLoading ? (
            <div className="router-loading-wrapper">
              <HashLoader color={colors.primaryBlue} size={80} />
            </div>
          ) : null}
          <Component {...props} />
        </Provider>
      </SessionProvider>
    </ConfigProvider>
  );
};

export default App;
