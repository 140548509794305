import { notification } from "antd";

const antdNotificationConfig = (): void => {
  notification.config({
    duration: 2,
    placement: "bottomRight",
  });
};

export default antdNotificationConfig;
