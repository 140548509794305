import { css } from "@emotion/react";

export default css`
  .ant-layout-header {
    background: var(--primary);
    align-items: center;
  }

  .ant-layout-sider,
  .ant-menu,
  .ant-layout-sider-children {
    background: var(--sidebar);
  }

  /* Don't force sidebar width if the sidebar is collapsed */
  .ant-layout-sider:not(.ant-layout-sider-collapsed) {
    width: var(--sidebar-width) !important;
    min-width: var(--sidebar-width) !important;
    max-width: var(--sidebar-width) !important;
  }

  .ant-layout-sider-zero-width-trigger {
    top: 128px;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    margin: unset;
    padding: 0;
    padding-bottom: 1rem;
    background-color: white;
    overflow-y: auto;

    &.padding-top {
      padding-top: 64px;
    }
  }

  .ant-layout-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    p {
      text-transform: uppercase;
      font-weight: 600;
    }

    .ant-row {
      align-items: center;
    }
  }

  .ant-menu-item {
    height: var(--menu-item-height);
    line-height: var(--menu-item-height);

    * {
      font-size: var(--menu-item-font-size);
    }
  }

  .ant-table {
    margin: 0 1rem;
    overflow-y: auto;
  }

  .ant-page-header {
    margin: 1rem;
    padding: 0;
  }

  .ant-table-pagination {
    justify-content: center;
  }

  .ant-layout-header {
    padding: 0;
    display: flex;
    justify-content: start;

    .ant-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 1rem;
    }
  }

  .flex-spacer {
    display: flex;
  }

  .workbench-ant-layout {
    padding-bottom: 0;
  }
`;
