import { createSlice } from "@reduxjs/toolkit";
import { DatasetItem } from "pages/DataCatalogue/Datasets/Datasets.functions";
import { FormattedUser } from "pages/UserManagement/Users/Users.functions";
import { DatasetTag, DocumentUploadSuccess } from "store/api/apiGenerated";

export interface WorkbenchDataset {
  datasets: DatasetItem[];
  totalTags: DatasetTag[];
  totalDatasets: number;
  totalSamples: number;
}

export interface WorkbenchSliceState {
  name: string;
  purpose: string;
  datasets: WorkbenchDataset;
  users: FormattedUser[];
  stepIndex: number;
  documents: DocumentUploadSuccess[];
  isInitialised: boolean;
  version?: number;
}

const initialState = {
  name: "",
  purpose: "",
  datasets: {
    datasets: [],
    totalTags: [],
    totalDatasets: 0,
    totalSamples: 0,
  },
  users: [],
  stepIndex: 0,
  documents: [],
  isInitialised: false,
};

const workbenchSlice = createSlice({
  name: "workbench",
  initialState: initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPurpose: (state, action) => {
      state.purpose = action.payload;
    },
    setDatasets: (state, action) => {
      state.datasets = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setInitialEditState: (_state, action) => action.payload,
    resetWorkbench: () => initialState,
  },
});

export const {
  setName,
  setPurpose,
  setDatasets,
  setUsers,
  setStepIndex,
  setDocuments,
  resetWorkbench,
  setInitialEditState,
} = workbenchSlice.actions;

export default workbenchSlice;
