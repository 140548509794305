import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import type { PayloadAction } from "@reduxjs/toolkit";
export interface AuthState {
  idToken: string | null;
}

const initialState: AuthState = {
  idToken: null,
};

interface HydrateAction extends PayloadAction<{ auth: any }> {}

const isHydrateAction = (action: AnyAction): action is HydrateAction => {
  return action.type === HYDRATE;
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIdToken: (state, action: PayloadAction<string | null>) => {
      state.idToken = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isHydrateAction, (state, action) => {
      return {
        ...state,
        ...action.payload.auth,
      };
    });
  },
});

export const { setIdToken } = AuthSlice.actions;

export default AuthSlice.reducer;
