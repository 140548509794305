import styled from "@emotion/styled";

export const CentreContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;

export const RowCenter = styled(Row)`
  align-items: center;
`;

export const Col = styled(Flex)`
  flex-direction: column;
`;

export const ColCenter = styled(Col)`
  align-items: center;
`;
