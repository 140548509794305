import { LogoutOutlined } from "@ant-design/icons";
import { CentreContainer, processSideBarItems, setIdToken } from "@project/core";
import { AppConfig, RouteType } from "@project/core/types";
import { captureException } from "@sentry/nextjs";
import { Layout, Menu, MenuProps, Row, Spin } from "antd";
import { signOut } from "next-auth/react";
import Head from "next/head";
import Link from "next/link";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Actor } from "../../../utils/authorization";
import styles from "./BaseLayout.styles";

const { Header, Content, Sider } = Layout;

export interface BaseLayoutProps<R extends RouteType> {
  appConfig: AppConfig<R>;
  iconParamsMap?: Record<string, unknown>;
  actor: Actor;
  title: string;
  logo: JSX.Element;
  pathForResolvedURL: string | null;
  showTitle?: boolean;
  showMenu?: boolean;
  theme?: "light" | "dark";
  children?: JSX.Element | JSX.Element[];
  buttonsElement?: JSX.Element | null;
  onSignOut?: () => void;
  className?: string;
  subPage?: boolean;
}

const BaseLayout = <R extends RouteType>({
  appConfig,
  iconParamsMap,
  actor,
  children,
  onSignOut,
  title,
  showTitle = true,
  showMenu = true,
  theme = "dark",
  logo,
  pathForResolvedURL,
  buttonsElement,
  className,
}: BaseLayoutProps<R>): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const showHeader = showTitle || buttonsElement;

  /**
   * The signOut function provided by next-auth simply signs the user out of the application -
   * it but does not sign them out of the Auth0 session.
   * We want them to be signed out of both so we add a callback which redirects the user to our custom logout route
   * implemented at src/pages/api/auth/logout.ts
   */
  const handleSignOut = async (): Promise<void> => {
    setLoading(true);

    if (onSignOut) onSignOut();

    dispatch(setIdToken(null));

    try {
      await signOut({ callbackUrl: "/api/auth/logout" });
    } catch (e) {
      captureException(e);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <CentreContainer>
        <Spin size="large" />
      </CentreContainer>
    );
  }

  const sidebarItems = processSideBarItems(appConfig, iconParamsMap ?? {}, actor);

  type MenuItem = Required<MenuProps>["items"][number];
  const menuItems: MenuItem[] = sidebarItems.map(item => ({
    key: item.text,
    icon: item.icon,
    label: <Link href={item.href}>{item.text}</Link>,
    className: pathForResolvedURL === item.href.pathname ? "propel-menu-item-active" : "",
  }));

  return (
    <Layout css={styles} hasSider={showMenu}>
      <Head>
        <title>{title}</title>
      </Head>

      {showMenu ? (
        <Sider breakpoint="lg" collapsedWidth={0}>
          <Link href={{ pathname: "/" }}>
            <a>{logo}</a>
          </Link>

          <Menu
            theme={theme}
            items={[
              ...menuItems,
              {
                key: "logout",
                icon: <LogoutOutlined />,
                label: <span>Logout</span>,
                className: "logout",
                onClick: handleSignOut,
              },
            ]}
          />
        </Sider>
      ) : null}

      <Content className={`${className} ${showHeader ? "padding-top" : ""}`}>
        {showHeader && (
          <Header>
            <Row>
              {showTitle && <p className={theme === "dark" ? "text-on-dark" : "text-on-light"}>{title}</p>}
              {buttonsElement && <div className="buttons-container">{buttonsElement}</div>}
            </Row>
          </Header>
        )}
        {children}
      </Content>
    </Layout>
  );
};

export default BaseLayout;
