import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    listAllUsersApiV1UsersGet: build.query<ListAllUsersApiV1UsersGetApiResponse, ListAllUsersApiV1UsersGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/users/`,
        params: {
          skip: queryArg.skip,
          limit: queryArg.limit,
          search: queryArg.search,
          order_by: queryArg.orderBy,
          status: queryArg.status,
          roles: queryArg.roles,
        },
      }),
    }),
    createUsersApiV1UsersPost: build.mutation<CreateUsersApiV1UsersPostApiResponse, CreateUsersApiV1UsersPostApiArg>({
      query: queryArg => ({ url: `/api/v1/users/`, method: "POST", body: queryArg.body }),
    }),
    getLoggedInUserApiV1UsersMeGet: build.query<
      GetLoggedInUserApiV1UsersMeGetApiResponse,
      GetLoggedInUserApiV1UsersMeGetApiArg
    >({
      query: () => ({ url: `/api/v1/users/me/` }),
    }),
    getUserApiV1UsersUserIdGet: build.query<GetUserApiV1UsersUserIdGetApiResponse, GetUserApiV1UsersUserIdGetApiArg>({
      query: queryArg => ({ url: `/api/v1/users/${queryArg.userId}/` }),
    }),
    updateInviteApiV1UsersInvitationUserIdUserEmailPost: build.mutation<
      UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiResponse,
      UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/users/invitation/${queryArg.userId}/${queryArg.userEmail}/`,
        method: "POST",
      }),
    }),
    disableUserApiV1UsersDisableUserIdPost: build.mutation<
      DisableUserApiV1UsersDisableUserIdPostApiResponse,
      DisableUserApiV1UsersDisableUserIdPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/users/disable/${queryArg.userId}/`, method: "POST" }),
    }),
    enableUserApiV1UsersEnableUserIdPost: build.mutation<
      EnableUserApiV1UsersEnableUserIdPostApiResponse,
      EnableUserApiV1UsersEnableUserIdPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/users/enable/${queryArg.userId}/`, method: "POST" }),
    }),
    revokeInviteApiV1UsersRevokeUserIdPost: build.mutation<
      RevokeInviteApiV1UsersRevokeUserIdPostApiResponse,
      RevokeInviteApiV1UsersRevokeUserIdPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/users/revoke/${queryArg.userId}/`, method: "POST" }),
    }),
    listAllDatasetsApiV1DatasetsGet: build.query<
      ListAllDatasetsApiV1DatasetsGetApiResponse,
      ListAllDatasetsApiV1DatasetsGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/datasets/`,
        params: { skip: queryArg.skip, limit: queryArg.limit, search: queryArg.search, order_by: queryArg.orderBy },
      }),
    }),
    listWorkbenchesApiV1WorkbenchesGet: build.query<
      ListWorkbenchesApiV1WorkbenchesGetApiResponse,
      ListWorkbenchesApiV1WorkbenchesGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/`,
        params: { skip: queryArg.skip, limit: queryArg.limit, search: queryArg.search },
      }),
    }),
    createWorkbenchApiV1WorkbenchesPost: build.mutation<
      CreateWorkbenchApiV1WorkbenchesPostApiResponse,
      CreateWorkbenchApiV1WorkbenchesPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/`, method: "POST", body: queryArg.workbenchCreate }),
    }),
    getWorkbenchApiV1WorkbenchesWorkbenchIdGet: build.query<
      GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiResponse,
      GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/${queryArg.workbenchId}/` }),
    }),
    updateWorkbenchApiV1WorkbenchesWorkbenchIdPut: build.mutation<
      UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiResponse,
      UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/`,
        method: "PUT",
        body: queryArg.workbenchUpdate,
      }),
    }),
    deleteWorkbenchApiV1WorkbenchesWorkbenchIdDelete: build.mutation<
      DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiResponse,
      DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/${queryArg.workbenchId}/`, method: "DELETE" }),
    }),
    getWorkbenchComputeConfigurationApiV1WorkbenchesWorkbenchIdComputeConfigurationGet: build.query<
      GetWorkbenchComputeConfigurationApiV1WorkbenchesWorkbenchIdComputeConfigurationGetApiResponse,
      GetWorkbenchComputeConfigurationApiV1WorkbenchesWorkbenchIdComputeConfigurationGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/compute-configuration/`,
        params: { zone: queryArg.zone },
      }),
    }),
    deleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDelete: build.mutation<
      DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiResponse,
      DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/${queryArg.notebookId}/`,
        method: "DELETE",
      }),
    }),
    listAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGet: build.query<
      ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiResponse,
      ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/`,
        params: { skip: queryArg.skip, limit: queryArg.limit, search: queryArg.search, order_by: queryArg.orderBy },
      }),
    }),
    createNotebookApiV1WorkbenchesWorkbenchIdNotebooksPost: build.mutation<
      CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiResponse,
      CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/`,
        method: "POST",
        body: queryArg.notebookCreate,
      }),
    }),
    startNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPost: build.mutation<
      StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiResponse,
      StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/${queryArg.notebookId}/start/`,
        method: "POST",
      }),
    }),
    stopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPost: build.mutation<
      StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiResponse,
      StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/${queryArg.notebookId}/stop/`,
        method: "POST",
      }),
    }),
    uploadDocumentsApiV1DocumentsPost: build.mutation<
      UploadDocumentsApiV1DocumentsPostApiResponse,
      UploadDocumentsApiV1DocumentsPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/documents/`,
        method: "POST",
        body: queryArg.bodyUploadDocumentsApiV1DocumentsPost,
      }),
    }),
    downloadDocumentApiV1DocumentsDocumentIdGet: build.query<
      DownloadDocumentApiV1DocumentsDocumentIdGetApiResponse,
      DownloadDocumentApiV1DocumentsDocumentIdGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/documents/${queryArg.documentId}/` }),
    }),
    deleteDocumentApiV1DocumentsDocumentIdDelete: build.mutation<
      DeleteDocumentApiV1DocumentsDocumentIdDeleteApiResponse,
      DeleteDocumentApiV1DocumentsDocumentIdDeleteApiArg
    >({
      query: queryArg => ({ url: `/api/v1/documents/${queryArg.documentId}/`, method: "DELETE" }),
    }),
    listAuditLogApiV1LogsGet: build.query<ListAuditLogApiV1LogsGetApiResponse, ListAuditLogApiV1LogsGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/logs/`,
        params: {
          search: queryArg.search,
          event_type: queryArg.eventType,
          order_by: queryArg.orderBy,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          skip: queryArg.skip,
          limit: queryArg.limit,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiGenerated };
export type ListAllUsersApiV1UsersGetApiResponse = /** status 200 Successful Response */ UserList;
export type ListAllUsersApiV1UsersGetApiArg = {
  skip?: number;
  limit?: number;
  search?: string;
  orderBy?: UserOrderBy;
  status?: UserStatus[];
  roles?: TenantRole[];
};
export type CreateUsersApiV1UsersPostApiResponse = /** status 200 Successful Response */ UserCreateReturn;
export type CreateUsersApiV1UsersPostApiArg = {
  body: string[];
};
export type GetLoggedInUserApiV1UsersMeGetApiResponse = /** status 200 Successful Response */ UserWithRoles;
export type GetLoggedInUserApiV1UsersMeGetApiArg = void;
export type GetUserApiV1UsersUserIdGetApiResponse = /** status 200 Successful Response */ UserWithRoles;
export type GetUserApiV1UsersUserIdGetApiArg = {
  userId: string;
};
export type UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiArg = {
  userId: string;
  userEmail: string;
};
export type DisableUserApiV1UsersDisableUserIdPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type DisableUserApiV1UsersDisableUserIdPostApiArg = {
  userId: string;
};
export type EnableUserApiV1UsersEnableUserIdPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type EnableUserApiV1UsersEnableUserIdPostApiArg = {
  userId: string;
};
export type RevokeInviteApiV1UsersRevokeUserIdPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type RevokeInviteApiV1UsersRevokeUserIdPostApiArg = {
  userId: string;
};
export type ListAllDatasetsApiV1DatasetsGetApiResponse = /** status 200 Successful Response */ DatasetList;
export type ListAllDatasetsApiV1DatasetsGetApiArg = {
  skip?: number;
  limit?: number;
  search?: string;
  orderBy?: DatasetOrderBy;
};
export type ListWorkbenchesApiV1WorkbenchesGetApiResponse = /** status 200 Successful Response */ WorkbenchList;
export type ListWorkbenchesApiV1WorkbenchesGetApiArg = {
  skip?: number;
  limit?: number;
  search?: string;
};
export type CreateWorkbenchApiV1WorkbenchesPostApiResponse = /** status 200 Successful Response */ Workbench;
export type CreateWorkbenchApiV1WorkbenchesPostApiArg = {
  workbenchCreate: WorkbenchCreate;
};
export type GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiResponse = /** status 200 Successful Response */ Workbench;
export type GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiArg = {
  workbenchId: string;
};
export type UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiResponse = /** status 200 Successful Response */ Workbench;
export type UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiArg = {
  workbenchId: string;
  workbenchUpdate: WorkbenchUpdate;
};
export type DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiArg = {
  workbenchId: string;
};
export type GetWorkbenchComputeConfigurationApiV1WorkbenchesWorkbenchIdComputeConfigurationGetApiResponse =
  /** status 200 Successful Response */ WorkbenchComputeConfiguration;
export type GetWorkbenchComputeConfigurationApiV1WorkbenchesWorkbenchIdComputeConfigurationGetApiArg = {
  workbenchId: string;
  zone?: string;
};
export type DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiArg = {
  notebookId: string;
  workbenchId: string;
};
export type ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiResponse =
  /** status 200 Successful Response */ NotebookList;
export type ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiArg = {
  workbenchId: string;
  skip?: number;
  limit?: number;
  search?: string;
  orderBy?: NotebookOrderBy;
};
export type CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiResponse =
  /** status 200 Successful Response */ Notebook;
export type CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiArg = {
  workbenchId: string;
  notebookCreate: NotebookCreate;
};
export type StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiResponse =
  /** status 200 Successful Response */ Notebook;
export type StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiArg = {
  notebookId: string;
  workbenchId: string;
};
export type StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiResponse =
  /** status 200 Successful Response */ Notebook;
export type StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiArg = {
  notebookId: string;
  workbenchId: string;
};
export type UploadDocumentsApiV1DocumentsPostApiResponse = /** status 200 Successful Response */ DocumentUploadReturn;
export type UploadDocumentsApiV1DocumentsPostApiArg = {
  bodyUploadDocumentsApiV1DocumentsPost: BodyUploadDocumentsApiV1DocumentsPost;
};
export type DownloadDocumentApiV1DocumentsDocumentIdGetApiResponse = {
  blobUrl: string;
};
export type DownloadDocumentApiV1DocumentsDocumentIdGetApiArg = {
  documentId: string;
};
export type DeleteDocumentApiV1DocumentsDocumentIdDeleteApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type DeleteDocumentApiV1DocumentsDocumentIdDeleteApiArg = {
  documentId: string;
};
export type ListAuditLogApiV1LogsGetApiResponse = /** status 200 Successful Response */ AuditLogEntryList;
export type ListAuditLogApiV1LogsGetApiArg = {
  search?: string;
  eventType?: AuditLogEventType;
  orderBy?: AuditLogOrderBy;
  startDate?: string;
  endDate?: string;
  skip?: number;
  limit?: number;
};
export type UserStatus = "PENDING" | "ACTIVE" | "DISABLED" | "DELETED";
export type TenantRole = "TENANT_ADMIN" | "TENANT_MEMBER";
export type UserWithRoles = {
  id: string;
  email: string;
  status: UserStatus;
  tenantRoles?: TenantRole[];
};
export type UserList = {
  users: UserWithRoles[];
  pageCount: number;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type UserOrderBy = "EMAIL_ASC" | "EMAIL_DESC" | "STATUS_ASC" | "STATUS_DESC";
export type UserCreateReturn = {
  createdUsers: UserWithRoles[];
  existingUsers: UserWithRoles[];
};
export type DatasetTag = {
  type: string;
  value: string;
};
export type Dataset = {
  id: string;
  name: string;
  description: string;
  samples: number;
  modified: string;
  tags: DatasetTag[];
};
export type DatasetList = {
  datasets: Dataset[];
  pageCount: number;
};
export type DatasetOrderBy =
  | "NAME_ASC"
  | "NAME_DESC"
  | "MODIFIED_ASC"
  | "MODIFIED_DESC"
  | "SAMPLES_ASC"
  | "SAMPLES_DESC";
export type WorkbenchStatus = "PENDING" | "PROVISIONING" | "FAILED_TO_PROVISION" | "ACTIVE" | "RETIRED";
export type WorkbenchRole = "WORKBENCH_ADMIN" | "WORKBENCH_MEMBER";
export type WorkbenchListItem = {
  name: string;
  description: string;
  id: string;
  status: WorkbenchStatus;
  projectId: string;
  userRoles?: WorkbenchRole[];
  version: number;
};
export type WorkbenchList = {
  workbenches: WorkbenchListItem[];
  pageCount: number;
};
export type ReviewStatus = "PENDING" | "APPROVED" | "REJECTED";
export type WorkbenchUser = {
  reviewedAt?: string;
  reviewMessage?: string;
  reviewedById?: string;
  reviewStatus: ReviewStatus;
  id: string;
  email: string;
  status: UserStatus;
  tenantRoles?: TenantRole[];
  workbenchRoles: WorkbenchRole[];
};
export type DatasetWithApproval = {
  reviewedAt?: string;
  reviewMessage?: string;
  reviewedById?: string;
  reviewStatus: ReviewStatus;
  id: string;
  name: string;
  description: string;
  samples: number;
  modified: string;
  tags: DatasetTag[];
};
export type DocumentUploadSuccess = {
  id: string;
  filename: string;
  created: string;
  href: string;
};
export type Workbench = {
  name: string;
  description: string;
  id: string;
  status: WorkbenchStatus;
  projectId: string;
  version: number;
  versionId: string;
  activeVersionId: string;
  users?: WorkbenchUser[];
  datasets?: DatasetWithApproval[];
  documents?: DocumentUploadSuccess[];
};
export type WorkbenchCreate = {
  name: string;
  description: string;
  datasets?: string[];
  users?: string[];
  documents?: string[];
};
export type WorkbenchUpdate = {
  name: string;
  description: string;
  datasets?: string[];
  users?: string[];
  documents?: string[];
  fromVersion: string;
};
export type NotebookMachineType = {
  machineType: string;
  description: string;
};
export type NotebookAcceleratorType =
  | "ACCELERATOR_TYPE_UNSPECIFIED"
  | "NVIDIA_TESLA_K80"
  | "NVIDIA_TESLA_P100"
  | "NVIDIA_TESLA_V100"
  | "NVIDIA_TESLA_P4"
  | "NVIDIA_TESLA_T4"
  | "TPU_V2"
  | "TPU_V3"
  | "NVIDIA_TESLA_T4_VWS"
  | "NVIDIA_TESLA_P100_VWS"
  | "NVIDIA_TESLA_P4_VWS"
  | "NVIDIA_TESLA_A100";
export type NotebookEnvironment = {
  imageName: string;
  projectId: string;
  description: string;
};
export type WorkbenchComputeConfiguration = {
  zone: string;
  allZones: string[];
  machineTypesForZone: NotebookMachineType[];
  gpuTypesForZone: NotebookAcceleratorType[];
  notebookEnvironments: NotebookEnvironment[];
};
export type NotebookState = "PROVISIONING" | "STARTING" | "ACTIVE" | "STOPPING" | "STOPPED" | "ERROR" | "DELETED";
export type Notebook = {
  id: string;
  name: string;
  workbenchId: string;
  location: string;
  createdById: string;
  updated: string;
  created: string;
  createdByEmail: string;
  state: NotebookState;
  machineType: string;
  gpuType: NotebookAcceleratorType;
};
export type NotebookList = {
  notebooks: Notebook[];
  pageCount: number;
};
export type NotebookOrderBy =
  | "NAME_ASC"
  | "NAME_DESC"
  | "CREATED_BY_EMAIL_ASC"
  | "CREATED_BY_EMAIL_DESC"
  | "UPDATED_ASC"
  | "UPDATED_DESC";
export type NotebookImage = {
  imageName: string;
  projectId: string;
};
export type NotebookCreate = {
  name: string;
  location: string;
  machineType: string;
  gpuType?: NotebookAcceleratorType;
  image: NotebookImage;
};
export type DocumentUploadFailure = {
  filename: string;
  message: string;
};
export type DocumentUploadReturn = {
  successes: DocumentUploadSuccess[];
  failures: DocumentUploadFailure[];
};
export type BodyUploadDocumentsApiV1DocumentsPost = {
  files: Blob[];
};
export type AuditLogEntry = {
  timestamp: string;
  message: string;
};
export type AuditLogEntryList = {
  entries: AuditLogEntry[];
  pageCount: number;
};
export type AuditLogEventType =
  | "All"
  | "Invitation Accepted"
  | "Invitation Declined"
  | "User Deactivated"
  | "User Invited"
  | "Workbench Created"
  | "Workbench Deleted"
  | "Cohort Published"
  | "User Logged In"
  | "User Logged Out";
export type AuditLogOrderBy = "TIMESTAMP_ASC" | "TIMESTAMP_DESC";
