import { authReducer } from "@project/core";
import { generateRTKErrorLogger, MessageMapper } from "@project/core/utils/errorLogger";
import { configureStore } from "@reduxjs/toolkit";
import { StatusCodes } from "http-status-codes";
import { createWrapper } from "next-redux-wrapper";
import workbenchSlice from "store/workbench/workbenchSlice";
import { propelApi } from "./api/apiSlice";

type Endpoints = keyof typeof propelApi.endpoints;

const apiErrorMessageMapper: MessageMapper<Endpoints> = (endpointName, status, apiErrorMessage) => {
  switch (endpointName) {
    case "getLoggedInUserApiV1UsersMeGet":
    case "getUserApiV1UsersUserIdGet":
    case "disableUserApiV1UsersDisableUserIdPost":
    case "enableUserApiV1UsersEnableUserIdPost":
      switch (status) {
        case StatusCodes.NOT_FOUND:
        case StatusCodes.UNAUTHORIZED:
          return { message: "User does not exist", unexpected: true };
      }
      break;
    case "listAllUsersApiV1UsersGet":
      switch (status) {
        case StatusCodes.NOT_FOUND:
        case StatusCodes.UNAUTHORIZED:
          return { message: "No users found", unexpected: true };
      }
      break;
    case "updateWorkbenchApiV1WorkbenchesWorkbenchIdPut":
      if (StatusCodes.CONFLICT) {
        return {
          message: "Error! Unable to submit edits.",
          description: apiErrorMessage,
          unexpected: true,
          isReload: true,
        };
      }
      break;
    case "createNotebookApiV1WorkbenchesWorkbenchIdNotebooksPost":
      if (StatusCodes.CONFLICT) {
        return { message: "Notebook Creation Error.", description: apiErrorMessage, unexpected: false };
      }

      return {
        message: "Notebook Creation Error.",
        description: "Sorry, there was an error in creating your notebook. Please try again.",
        unexpected: true,
      };
  }
  return { message: "Something went wrong", unexpected: true };
};

/*
 disabling this rule as we want the RTK smarts to take care of defining the types
 so that we can create a type definition based on this function definition
*/
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeStore = () =>
  configureStore({
    reducer: {
      [propelApi.reducerPath]: propelApi.reducer,
      auth: authReducer,
      [workbenchSlice.name]: workbenchSlice.reducer,
    },
    devTools: true,
    middleware: gDM => gDM().concat([propelApi.middleware, generateRTKErrorLogger(apiErrorMessageMapper)]),
  });

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
